import * as React from 'react'
import {
  graphql,
  useStaticQuery
} from 'gatsby'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import i18n from '../../i18n.js'

import BlogTile from '../../components/blogTile.js'
import CategoryList from '../../components/categoryList'
import SidebarLayout from '../../components/sidebarLayout'

import * as Styles from '../../styles/categories.module.scss' 

const Category = ({ pageContext }) => {
  

  var translationContext = { locale: pageContext.locale,
                             defaultMenu: true };

const {t} = i18n
  const category = pageContext.node.data
  const posts = pageContext.node.posts.filter((post) => post.frontmatter.locale === pageContext.locale)
  const queryResult = useStaticQuery(graphql`
    query  allCategories{       
        allAirtableCategories(sort: {fields: data___Name}) {
            nodes {
              data {
                  Slug
                  Name
                  Locale
              }
            }
        }      
     } 
`)
  const allCategories = queryResult.allAirtableCategories.nodes.filter((data => data.data.Locale === pageContext.locale ))

  return (
    <SidebarLayout title={category.Name}
      pageContext={pageContext}
      hideTitle={true}
      translationContext={translationContext}
      main={(
         
        <div  class="main-padding">
      
          <h1>{t('blogCategory')} "{category.Name}"</h1>


          <div class={Styles.postGrid}>
            {posts === undefined || posts === null || posts.length === 0
              ? (<div>Keine Blog Artikel vorhanden: {posts.toString()}</div>)
              : posts.sort((a, b) => a.frontmatter.dateSort > b.frontmatter.dateSort ? -1 : 1).map(post => (
                <BlogTile blogNode={post} />
              ))}
          </div>
          <MDXRenderer>
            {category.Markdown.childMdx.body}
          </MDXRenderer>
        </div>
      )}

      sidebar={(
        <div>
          <CategoryList nodes={allCategories}
                        title={t('categoryTitleOther')}
                        locale={pageContext.locale} />
        </div>
      )} />
  )
}

export default Category